import routerOptions0 from "/home/runner/work/hokify/hokify/node_modules/.pnpm/nuxt@3.10.3_@types+node@18.19.41_eslint@8.57.0_sass@1.44.0_typescript@5.1.6/node_modules/nuxt/dist/pages/runtime/router.options";
import routerOptions1 from "/home/runner/work/hokify/hokify/apps/international-page-app/src/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}