import revive_payload_client_ui2JxCYgcI from "/home/runner/work/hokify/hokify/node_modules/.pnpm/nuxt@3.10.3_@types+node@18.19.41_eslint@8.57.0_sass@1.44.0_typescript@5.1.6/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_7JPov3r0mx from "/home/runner/work/hokify/hokify/node_modules/.pnpm/nuxt@3.10.3_@types+node@18.19.41_eslint@8.57.0_sass@1.44.0_typescript@5.1.6/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_5MB7dLX0jX from "/home/runner/work/hokify/hokify/node_modules/.pnpm/nuxt@3.10.3_@types+node@18.19.41_eslint@8.57.0_sass@1.44.0_typescript@5.1.6/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_GlH77wHg0i from "/home/runner/work/hokify/hokify/node_modules/.pnpm/nuxt@3.10.3_@types+node@18.19.41_eslint@8.57.0_sass@1.44.0_typescript@5.1.6/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_uWcF4tQHcO from "/home/runner/work/hokify/hokify/node_modules/.pnpm/nuxt@3.10.3_@types+node@18.19.41_eslint@8.57.0_sass@1.44.0_typescript@5.1.6/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/home/runner/work/hokify/hokify/apps/international-page-app/.nuxt/components.plugin.mjs";
import prefetch_client_gLDORYqQua from "/home/runner/work/hokify/hokify/node_modules/.pnpm/nuxt@3.10.3_@types+node@18.19.41_eslint@8.57.0_sass@1.44.0_typescript@5.1.6/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import chunk_reload_client_6pqQ9MO9Ex from "/home/runner/work/hokify/hokify/node_modules/.pnpm/nuxt@3.10.3_@types+node@18.19.41_eslint@8.57.0_sass@1.44.0_typescript@5.1.6/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import insidePagePlugin_JY7CYx6iLI from "/home/runner/work/hokify/hokify/apps/international-page-app/src/plugins/insidePagePlugin.ts";
import ismobile_d00yZMP9Wz from "/home/runner/work/hokify/hokify/apps/international-page-app/src/plugins/ismobile.ts";
import modalPlugin_J7c0j9qUKn from "/home/runner/work/hokify/hokify/apps/international-page-app/src/plugins/modalPlugin.ts";
import sentry_client_KAXFuL2wum from "/home/runner/work/hokify/hokify/apps/international-page-app/src/plugins/sentry.client.ts";
import snackbar_client_LfkENc4klY from "/home/runner/work/hokify/hokify/apps/international-page-app/src/plugins/snackbar.client.ts";
export default [
  revive_payload_client_ui2JxCYgcI,
  unhead_7JPov3r0mx,
  router_5MB7dLX0jX,
  payload_client_GlH77wHg0i,
  check_outdated_build_client_uWcF4tQHcO,
  components_plugin_KR1HBZs4kY,
  prefetch_client_gLDORYqQua,
  chunk_reload_client_6pqQ9MO9Ex,
  insidePagePlugin_JY7CYx6iLI,
  ismobile_d00yZMP9Wz,
  modalPlugin_J7c0j9qUKn,
  sentry_client_KAXFuL2wum,
  snackbar_client_LfkENc4klY
]